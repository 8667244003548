import zhAnt from 'ant-design-vue/locale/zh_CN'
import enAnt from 'ant-design-vue/locale/en_US'
import en from './messages/en.json'
import zh from './messages/zh.json'

import zhSwap from '@/pages-components/bridgeswap/components/DappPay/locales/zh.json'
import enSwap from '@/pages-components/bridgeswap/components/DappPay/locales/en.json'

const messages = {
  zh: { antd: zhAnt, ...zh, ...zhSwap },
  en: { antd: enAnt, ...en, ...enSwap },
}
const hm = { hour: '2-digit', minute: '2-digit', hour12: false } as const
const dTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false } as const
const dDate = { year: 'numeric', month: '2-digit', day: '2-digit' } as const
const dFull = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
} as const
/** 月/日 时:分 */
const dMdhm = { month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: false }
const datetimeFormats = {
  zh: { sm: dTime, lg: dFull, time: dTime, date: dDate, full: dFull, mdhm: dMdhm, hm },
  en: { sm: dTime, lg: dFull, time: dTime, date: dDate, full: dFull, mdhm: dMdhm, hm },
} as const

export default defineI18nConfig(() => {
  return {
    locale: 'zh',
    fallbackLocale: 'en',
    legacy: false,
    messages,
    datetimeFormats,
    messageResolver(msgs: any, k: string) {
      const content = msgs[k]

      // 针对 AntD 的本地化内容
      if (k === 'antd')
        return () => JSON.stringify(content)

      // 静态资源本地化链接
      // if (k.startsWith('assets/')) {
      //   // console.log('🤡 / messageResolver / url:');
      //   return (ctx: any) => {
      //     const url = content(ctx);
      //     const url_2 = new URL('../' + content(ctx), import.meta.url).href;
      //     console.log('🤡 / return / url:', url_2);
      //     return url;
      //   };
      // }

      return content
    },
  }
})
